import { createCSSLinkElement, createScriptElement } from '@ems/common';
import { AppConfiguration } from './stores/appConfiguration';

const getAppFilename = (extension: string) => `emsQuotationApp.${extension}`;

const sentryUrl = 'https://js.sentry-cdn.com/b3a18b0369509a25511e6bbaecfb808f.min.js';

export const loadAndStart = (appConfig: AppConfiguration) => {
    const {
        configuration: { assetUrl },
    } = appConfig;

    const appCSSPath = `${assetUrl}${getAppFilename('css')}`;
    const appJSPath = `${assetUrl}${getAppFilename('js')}`;

    window.document.head.append(
        createScriptElement({
            src: appJSPath,
            onload: () => {
                window.Mews.startEmsQuotation(appConfig);
            },
        }),
        createScriptElement({ src: sentryUrl, crossOrigin: 'anonymous' }),
        createCSSLinkElement({ href: appCSSPath })
    );
};
