export const createCSSLinkElement = ({
    href,
    integrity,
    crossOrigin,
    referrerPolicy,
}: Pick<HTMLLinkElement, 'href'> & Partial<Pick<HTMLLinkElement, 'integrity' | 'crossOrigin' | 'referrerPolicy'>>) => {
    const stylesheet = window.document.createElement('link');
    stylesheet.href = href;
    stylesheet.rel = 'stylesheet';
    if (integrity) stylesheet.integrity = integrity;
    if (crossOrigin) stylesheet.crossOrigin = crossOrigin;
    if (referrerPolicy) stylesheet.referrerPolicy = referrerPolicy;

    return stylesheet;
};

export const createScriptElement = ({
    src,
    onload,
    crossOrigin,
    integrity,
}: Pick<HTMLScriptElement, 'src'> & Partial<Pick<HTMLScriptElement, 'onload' | 'crossOrigin' | 'integrity'>>) => {
    const script = window.document.createElement('script');
    script.src = src;
    if (onload) script.onload = onload;
    if (crossOrigin) script.crossOrigin = crossOrigin;
    if (integrity) script.integrity = integrity;
    return script;
};
